import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { signIn, signUp, confirmSignUp } from 'aws-amplify/auth';
import { LoaderButton } from './LoaderButton';
import { useRouter, useFormFields } from '../assets/scripts/hooksLib';
import { onError } from '../assets/scripts/errorLib';
import '../assets/styles/signup.scss';

/**
 * User account sign up form
 * @returns {HTMLElement} html for signup block
 */
export default function Signup() {
    const router = useRouter();
    const [fields, handleFieldChange] = useFormFields({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        confirmationCode: ''
    });
    const [newUser, setNewUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lateConfirm, setLateConfirm] = useState(false);

    // check if this is a late confirmation or not
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                if (router.query.confirm) {
                    setLateConfirm(true);
                }
            } catch (e) {
                if (e.response && e.response.status === 404) {
                    location.href = '/';
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    location.href = `/login.html?redirect=${router.pathname}${router.query}`;
                } else {
                    onError(e);
                }
            } finally {
                // setPageLoading(false);
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setLateConfirm]);

    // If the confirmation code is in the http query then update the form field with it
    // This only runs the first time the page is loaded, it won't run on consecutive
    // page renderings.
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                if (router.query.confirm) {
                    handleFieldChange({
                        target: {
                            id: 'confirmationCode', value: router.query.confirm
                        }
                    });
                }
            } catch (e) {
                onError(e);
            } finally {
                // setPageLoading(false);
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, []);

    /**
     * Returns true if required fields are complete
     * @returns {boolean} true or false
     */
    const validateForm = () => {
        return (
            fields.name.length > 0 &&
            fields.email.length > 0 &&
            fields.password.length > 0 &&
            fields.password === fields.confirmPassword
        );
    };

    /**
     * Returns true if confirmation code has been input
     * @returns {boolean} true or false
     */
    const validateConfirmationForm = () => {
        return fields.confirmationCode.length > 0;
    };

    /**
     * Returns true if confirmation code AND email address have been input
     * @returns {boolean} true or false
     */
    const validateConfirmationFormWithEmail = () => {
        return (
            fields.email.length > 0 &&
            fields.confirmationCode.length > 0
        );
    };

    /**
     * Submits the form fields to create a new user
     * @param {MouseEvent} event mouse click
     * @returns {void}
     */
    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            const newUser = await signUp({
                username: fields.email,
                password: fields.password,
                options: {
                    userAttributes: {
                        name: fields.name,
                        email: fields.email,
                        updated_at: `${Date.now()}`,
                        'custom:created_at': `${Date.now()}`
                    }
                }
            });

            setNewUser(newUser);
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Handles the email confirmation code confirmation
     * @param {MouseEvent} event mouse click
     * @returns {void}
     */
    const handleConfirmationSubmit = async (event) => {
        event.preventDefault();

        try {
            await confirmSignUp(fields.email, fields.confirmationCode);

            if (fields.email && fields.password) {
                await signIn(fields.email, fields.password);

                userHasAuthenticated(true);

                if (router.query && router.query.redirect) {
                    location.href = router.query.redirect;
                } else {
                    location.href = '/login.html';
                }
            } else {
                location.href = `/login.html${router.query.redirect ? '?redirect=' + router.query.redirect : ''}`;
            }
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    };

    /**
     * Builds the confirmation code form, which is immediately displayed after signup
     * @returns {HTMLElement} html
     */
    const renderConfirmationForm = () => {
        return (
            <form onSubmit={handleConfirmationSubmit}>
                <Form.Group controlId='confirmationCode'>
                    <Form.Label>Confirmation Code</Form.Label>
                    <Form.Control
                        autoFocus
                        type='tel'
                        onChange={handleFieldChange}
                        value={fields.confirmationCode}
                    />
                    <small className='form-text text-muted'>Please check your email for the code.</small>
                </Form.Group>
                <LoaderButton
                    block='true'
                    type='submit'
                    isLoading={isLoading}
                    disabled={!validateConfirmationForm()}
                >
                    Verify
                </LoaderButton>
            </form>
        );
    };

    /**
     * Builds the confirmation code form with Email field, as the assumptio is
     * the new user is coming from a link from their email
     * @returns {HTMLElement} html
     */
    const renderConfirmationFormWithEmail = () => {
        return (
            <form onSubmit={handleConfirmationSubmit}>
                <Form.Group controlId='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type='email'
                        value={fields.email}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId='confirmationCode'>
                    <Form.Label>Confirmation Code</Form.Label>
                    <Form.Control
                        autoFocus
                        type='tel'
                        onChange={handleFieldChange}
                        value={fields.confirmationCode}
                    />
                    <small className='form-text text-muted'>Please check your email for the code.</small>
                </Form.Group>
                <LoaderButton
                    block='true'
                    type='submit'
                    isLoading={isLoading}
                    disabled={!validateConfirmationFormWithEmail()}
                >
                    Verify
                </LoaderButton>
            </form>
        );
    };

    /**
     * Builds the new user form
     * @returns {HTMLElement} html
     */
    const renderForm = () => {
        return (
            <form onSubmit={handleSubmit}>
                <Form.Group controlId='name'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        autoFocus
                        type='name'
                        value={fields.name}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type='email'
                        value={fields.email}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type='password'
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId='confirmPassword'>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type='password'
                        onChange={handleFieldChange}
                        value={fields.confirmPassword}
                    />
                </Form.Group>
                <LoaderButton
                    className='mt-3'
                    block='true'
                    type='submit'
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Signup
                </LoaderButton>
                <div>&nbsp;</div>
                <Row>
                    <Col>
                        <h6 className='text-body-emphasis'>Password Requirments</h6>
                        <ul className="list-unstyled">
                            <li className="list-text-body-secondary">* Contains at least 1 number</li>
                            <li className="list-text-body-secondary">* Contains at least 1 special character</li>
                            <li className="list-text-body-secondary">* Contains at least 1 uppercase letter</li>
                            <li className="list-text-body-secondary">* Contains at least 1 lowercase letter</li>
                            <li className="list-text-body-secondary">* Minimum 8 characters in length</li>
                        </ul>
                    </Col>
                </Row>
            </form>
        );
    };

    return (
        <div className='Signup'>
            {newUser === null ? lateConfirm ? renderConfirmationFormWithEmail() : renderForm() : renderConfirmationForm()}
        </div>
    );
}
